@font-face {
  src: url("fonts/Montserrat-Thin.woff2") format("woff2");
  font-family: "Montserrat";
  font-weight: 100;
}

@font-face {
  src: url("fonts/Montserrat-ExtraLight.woff2") format("woff2");
  font-family: "Montserrat";
  font-weight: 200;
}

@font-face {
  src: url("fonts/Montserrat-Light.woff2") format("woff2");
  font-family: "Montserrat";
  font-weight: 300;
}

@font-face {
  src: url("fonts/Montserrat-Regular.woff2") format("woff2");
  font-family: "Montserrat";
  font-weight: 400;
}

@font-face {
  src: url("fonts/Montserrat-Medium.woff2") format("woff2");
  font-family: "Montserrat";
  font-weight: 500;
}

@font-face {
  src: url("fonts/Montserrat-Bold.woff2") format("woff2");
  font-family: "Montserrat";
  font-weight: 700;
}

@font-face {
  src: url("fonts/Montserrat-ExtraBold.woff2") format("woff2");
  font-family: "Montserrat";
  font-weight: 800;
}

@font-face {
  src: url("fonts/Montserrat-Black.woff2") format("woff2");
  font-family: "Montserrat";
  font-weight: 900;
}
