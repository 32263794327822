@import "mixins";

// https://ui.rujira.network/colors

// primary colors
$color-pink: hsl(295 89% 50%); // #D615EB - Primary pink
$color-purple: hsl(259 90% 58%); // #8436F5 - Primary purple
$color-purple-dark: hsl(258 76% 49%); // #5A2AD1 - Deep purple
$color-navy: hsl(234 85% 17%); // #070E50 - Dark navy
$color-gray-light: hsl(231 24% 94%); // #ECEDF2 - Light gray
$color-gray: hsl(251 19% 86%); // #D9D5E2 - Gray

// secondary colors
$color-blue: hsl(207 82% 51%); // #1e92e6 - Primary blue, used for info
$color-blue-dark: hsl(207 72% 35%); // #1c6599 - Darker blue variant
$color-teal: hsl(165 96% 69%); // #60fbd0 - Bright teal
$color-teal-dark: hsl(168 52% 44%); // #34aa89 - Muted teal, used for success
$color-orange: hsl(32 100% 48%); // #f57c00 - Warning orange
$color-orange-dark: hsl(4 100% 56%); // #ff5722 - Deep orange
$color-red: hsl(4 79% 56%); // #e53935 - Error red
$color-red-dark: hsl(0 72% 41%); // #B71C1C - Dark red

// greys
$grey: hsl(200 15% 53%); // #71909F - Standard grey
$lightGrey: hsl(200 15% 62%); // #90a4ae - Light grey
$darkGrey: hsl(231 17% 16%); // #22242f - Dark grey
$black: hsl(234 17% 11%); // #161721 - Black
$white: hsl(0 0% 100%); // #ffffff - White

:root {
  // sizes
  --border-width: 1px;
  --border-radius: 8px;
  --grid-gap: 20px;
  --card-padding: 28px;
  --card-padding-small: 20px;
  --header-height: 64px;
  --mobile-tab-height: 60px;
  --max-width: 1080px;
  --max-width-small: 640px;
  --modal-width: 520px;
  --input-height: 45px;

  // fonts
  --font-family-monospace: "Source Code Pro", monospace;
  --font-size: 14px;
  --font-size-small: 12px;
  --normal: 400;
  --bold: 500;

  // components
  --box-shadow: 0 4px 8px 0 hsl(0 0% 0% / 0.2);

  // miscellaneous
  --transition: 100ms;

  @include desktop {
    --main-padding-horizontal: 64px;
  }

  @include mobile {
    --main-padding-horizontal: 20px;
  }
}

:root {
  --button-primary-bg-h: 295;
  --button-primary-bg-s: 89%;
  --button-primary-bg-l: 50%;
  --button-primary-bg: hsl(
    var(--button-primary-bg-h),
    var(--button-primary-bg-s),
    var(--button-primary-bg-l)
  );
  --button-danger-bg-h: 356;
  --button-danger-bg-s: 100%;
  --button-danger-bg-l: 67%;
  --button-danger-bg: hsl(
    var(--button-danger-bg-h),
    var(--button-danger-bg-s),
    var(--button-danger-bg-l)
  );
  --button-primary-text: hsl(0 0% 95%);
  --button-default-bg-h: 222;
  --button-default-bg-s: 77%;
  --button-default-bg-l: 95%;
  --button-default-bg: hsl(
    var(--button-default-bg-h),
    var(--button-default-bg-s),
    var(--button-default-bg-l)
  );
  --button-default-text: #{$color-purple};

  --menu-bg: hsl(215 100% 33%);
  --menu-border: hsl(0 0% 100% / 0.1);
  --menu-text: hsl(0 0% 91%);

  --header: #{$grey};
  --link: #{$color-pink};
  --text: #{$darkGrey};
  --text-muted: #{$grey}; //hsl(227 51% 70%);
  --chart: #{$color-pink};

  --bg: hsl(230 100% 99%);
  --bg-muted: hsl(229 73% 97%);
  --card-bg: hsl(0 0% 100%);
  --card-border: hsl(222 48% 95%);
  --input-bg: var(--card-bg);
  --input-border: hsl(220 39% 86%);

  --info: #{$color-blue}; // Primary blue - Information
  --info-light: #{$color-blue} / 0.1;
  --warning: #{$color-orange}; // Warning orange - Attention/Warning
  --warning-light: #{$color-orange} / 0.1;
  --success: #{$color-teal-dark}; // Dark teal - Success/Completion
  --success-light: #{$color-teal-dark} / 0.1;
  --danger: #{$color-red}; // Error red - Danger/Error
  --danger-light: #{$color-red} / 0.1;

  --extension-bg: hsl(227 62% 11%);
  --extension-text: hsl(210 89% 61%);
}

.dark {
  --button-primary-bg-h: 295;
  --button-primary-bg-s: 89%;
  --button-primary-bg-l: 50%;
  --button-primary-bg: hsl(
    var(--button-primary-bg-h),
    var(--button-primary-bg-s),
    var(--button-primary-bg-l)
  );
  --button-primary-text: hsl(0 0% 95%);
  --button-default-bg-h: 200;
  --button-default-bg-s: 15%;
  --button-default-bg-l: 20%;
  --button-default-bg: hsl(
    var(--button-default-bg-h),
    var(--button-default-bg-s),
    var(--button-default-bg-l)
  );
  --button-default-text: #{$color-gray}; // hsl(0 0% 87%);

  --menu-bg: #{$darkGrey};
  --menu-border: #{$grey};
  --menu-text: hsl(0 0% 91%);

  --header: #{$grey};
  --link: #{$color-pink};
  --text: #{$color-gray};
  --text-muted: #{$grey};
  --chart: #{$color-pink};

  --bg: #{$black};
  --bg-muted: #{$grey};
  --card-bg: #{$darkGrey};
  --card-border: #{$black};
  --input-bg: var(--card-bg);
  --input-border: #{$grey}; //hsl(0 0% 30%);

  --extension-bg: #{$black};
  --extension-text: #{$color-pink};
}
